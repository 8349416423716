import {useEffect} from "react";

const useClickOutside = (ref, fn) => {
  useEffect(() => {
    const element = ref?.current;
    function handleClickOutside(event) {
      if (element && !element.contains(event.target)) {
        fn();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, fn]);
};

export default useClickOutside;
