import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import {observer} from "mobx-react";
import {useState, useRef} from "react";
import {CSSTransition} from "react-transition-group";
import useClickOutside from "@/hooks/useClickOutside";
import useScrollLock from "@/hooks/useScrollLock";
import {cn} from "@/utils/cn";
import useOverlay from "@/hooks/useOverlay";
import useCustomEventListener from "@/hooks/useCustomEventListener";

const Modal = observer(
  ({
    id,
    defaultOpen,
    onClose,
    children,
    className,
    overHeader = false,
    animationClass = "slide-right",
  }) => {
    const [open, setOpen] = useState(defaultOpen ?? false);
    const asideRef = useRef();

    // This prevents multiple unnecessary renders from useClickOutside.
    const handleToggle = isOpen => {
      if (isOpen !== undefined) {
        if (isOpen !== open) {
          setOpen(isOpen);
        }
      } else {
        setOpen(open => !open);
      }
    };

    // Close modal on click outside.
    useClickOutside(asideRef, () => {
      handleToggle(false);
      onClose && onClose();
    });

    // Lock side scrolling.
    useScrollLock(open);

    // Show overlay panel when open.
    useOverlay(open, overHeader);

    const handleToggleEvent = evt => {
      handleToggle(evt?.open);
    };

    useCustomEventListener(`${id}:modal:toggle`, handleToggleEvent);

    return ReactDOM.createPortal(
      <CSSTransition
        nodeRef={asideRef}
        in={open}
        timeout={400}
        mountOnEnter
        unmountOnExit
        classNames={animationClass}
      >
        <aside ref={asideRef} className={cn(className)}>
          {children}
        </aside>
      </CSSTransition>,
      document.body,
    );
  },
);

Modal.propType = {
  id: PropTypes.string.isRequired,
  defaultOpen: PropTypes.bool,
  children: PropTypes.children,
  onClose: PropTypes.func,
  className: PropTypes.string,
  transitionClass: PropTypes.string,
};

export default Modal;
